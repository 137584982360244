import React, { useState, useEffect } from 'react'
import { Fragment } from 'react'
import { CourseType } from 'types/CourseAdditionMetaData'
import AddClassesTable from '../../components/admin/AddClassesTable'
// import { exportToCsv } from '../course/[id]'
import { markAsRead } from 'API/notifications'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import Form from 'react-bootstrap/Form'
import './style.css'
import { Link } from 'gatsby'
import './style.scss'
import { getUrl } from 'API/getUrl'

export default function Notifications() {
	const [tableData, setTableData] = useState<Array<any>>([])
	const [query, setquery] = useState('')
	const { notifications } = useSelector((state: RootState) => state.UserReducer)

	useEffect(() => {
		if (notifications) {
			const data: any = []
			notifications.forEach((elem) => {
				data.push({
					...elem,
					date: new Date(elem.posted_on).toLocaleString(),
				})
			})
			{
				console.log(data)
			}
			setTableData(data)
		}
	}, [notifications])

	return (
		<Fragment>
			{/* <div className='col-3'>
				<h2>Notifications</h2>
			</div> */}
			<section className='section-50'>
				<div className='container'>
					<h3 className='m-b-50 heading-line'>
						Notifications <i className='fa fa-bell text-muted' />
					</h3>
					<div style={{ margin: 'auto' }}>
						{/* <input type='text' placeholder='search' onChange={(e) => setquery(e.target.value)} /> */}
						<Form.Control
							type='search'
							onChange={(e) => setquery(e.target.value)}
							placeholder='Search'
							className='me-2'
							aria-label='Search'
						/>
					</div>
					<div className='notification-ui_dd-content'>
						{tableData
							?.filter((ele) => ele?.title.toLowerCase().includes(query.toLowerCase()))
							.map((x, ind) => (
								<div className='notification-list' key={ind}>
									<div className='notification-list_content'>
										<div className='notification-list_detail'>
											<p>
												<b>Sender :</b> {x.added_by?.first_name} {x.added_by?.last_name}
												<br></br>
												<b>Subject:</b> {x?.title}
											</p>
											<textarea
												disabled
												value={x.description.substring(3, x.description.length - 4)}
												cols={80}
												rows={5}
											></textarea>
											<p className='text-muted'>
												<small>{new Date(x.posted_on).toLocaleString()}</small>
											</p>
										</div>
									</div>

									{x.read === false && (
										<div className='notification-list_feature-img'>
											<div
												className='btn btn-primary'
												onClick={async () => {
													// navigate(`/admin/user/${e}`)

													const mark_as_read = await markAsRead(x.notification_id)
													console.log(mark_as_read)
													if (mark_as_read) {
														console.log(mark_as_read)
														window?.location?.reload()
													}
												}}
											>
												Mark As Read
											</div>
										</div>
									)}
									{x.read && (
										<>
											<div className='text-success text-justify text-center'>
												<div>Seen</div>
											</div>
										</>
									)}
									{x.cdn !== null && x.cdn_file !== '' && (
										<iframe
											title='Attachment'
											src={`${x?.cdn_file ? getUrl(`static/${x?.cdn_file}?token=${x.cdn_token}`) : ''}#toolbar=0`}
											style={{ width: '100%', height: '30rem' }}
										></iframe>
									)}
								</div>
							))}
						{/* <div className='notification-list notification-list--unread'>
							<div className='notification-list_content'>
								<div className='notification-list_img'>
									<img src='images/users/user1.jpg' alt='user' />
								</div>
								<div className='notification-list_detail'>
									<p>
										<b>John Doe</b> reacted to your post
									</p>
									<p className='text-muted'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde, dolorem.</p>
									<p className='text-muted'>
										<small>10 mins ago</small>
									</p>
								</div>
							</div>
							<div className='notification-list_feature-img'>
								<img src='images/features/random1.jpg' alt='Feature image' />
							</div>
						</div>
						<div className='notification-list notification-list--unread'>
							<div className='notification-list_content'>
								<div className='notification-list_img'>
									<img src='images/users/user2.jpg' alt='user' />
								</div>
								<div className='notification-list_detail'>
									<p>
										<b>Richard Miles</b> liked your post
									</p>
									<p className='text-muted'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde, dolorem.</p>
									<p className='text-muted'>
										<small>10 mins ago</small>
									</p>
								</div>
							</div>
							<div className='notification-list_feature-img'>
								<img src='images/features/random2.jpg' alt='Feature image' />
							</div>
						</div>
						<div className='notification-list'>
							<div className='notification-list_content'>
								<div className='notification-list_img'>
									<img src='images/users/user3.jpg' alt='user' />
								</div>
								<div className='notification-list_detail'>
									<p>
										<b>Brian Cumin</b> reacted to your post
									</p>
									<p className='text-muted'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde, dolorem.</p>
									<p className='text-muted'>
										<small>10 mins ago</small>
									</p>
								</div>
							</div>
							<div className='notification-list_feature-img'>
								<img src='images/features/random3.jpg' alt='Feature image' />
							</div>
						</div> */}
					</div>
				</div>
			</section>

			{/* <AddClassesTable data={tableData} columns={columns} /> */}
		</Fragment>
	)
}
